import request from "@/utils/request";
import qs from "qs";
// RFQ分页
export function findByPage(query) {
  return request({
    method: "POST",
    url: "/foreign/rfq/findByPage",
    data: qs.stringify(query),
  });
}
// 外贸官网发布RFQ
export function createRFQ(query) {
  return request({
    method: "POST",
    url: "/foreign/rfq/createRFQ",
    data: qs.stringify(query),
  });
}
// 获取数量单位
export function findQuantityUnit(query) {
  return request({
    method: "POST",
    url: "/foreign/rfq/findQuantityUnit",
    data: qs.stringify(query),
  });
}
