<template>
  <div>
    <shop-head :yellowType="true" currentPageName="RFQs"></shop-head>
    <!-- 主要内容 -->
    <div class="content_wrapper">
      <div class="container">
        <!-- 面包屑 -->
        <div class="breadcrumb-item">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
              >HOME</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/RFQs' }"
              >RFQs</el-breadcrumb-item
            >
            <el-breadcrumb-item>Request for Quotation</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="add-cont">
          <el-card class="box-card">
            <div class="title">Post your request</div>
            <el-card class="box-card">
              <el-form
                label-position="top"
                ref="ruleForm"
                :rules="rules"
                :model="form"
              >
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-form-item label="Product name" prop="productName">
                      <el-input v-model.trim="form.productName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-form-item label="VIN" prop="vin">
                      <el-input v-model.trim="form.vin"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-form-item label="Car Type" prop="carType">
                      <el-input v-model.trim="form.carType"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <el-form-item label="Sourcing quantity" prop="quantity">
                      <el-input-number
                        style="width: 50%; margin-right: 20px"
                        v-model="form.quantity"
                        :min="1"
                        :max="100000000"
                        :controls="false"
                      ></el-input-number>
                      <el-form-item
                        style="display: inline-block"
                        label=""
                        prop="unit"
                      >
                        <el-select
                          v-model="form.unit"
                          filterable
                          placeholder="Please select"
                        >
                          <el-option
                            v-for="item in unitOptions"
                            :key="item.id"
                            :label="item.keyS"
                            :value="item.keyS"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <el-form-item label="Detailed requirements" prop="require">
                      <el-input
                        type="textarea"
                        :rows="10"
                        placeholder="I am looking for..."
                        v-model="form.require"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <div v-if="fileList.length > 0" class="file-box">
                <div
                  class="file-item"
                  v-for="(item, index) in fileList"
                  :key="index"
                >
                  <el-image
                    v-if="item.imgFileType === 'image'"
                    style="border: 0"
                    class="upload-show-img"
                    :src="item.imgUrl"
                    :preview-src-list="imgSrcList"
                    :title="item.name"
                  >
                  </el-image>
                  <div
                    @click="clickFile(item.imgUrl)"
                    v-else
                    class="upload-show-img"
                    :title="item.name"
                  >
                    File
                  </div>
                  <div class="remove-icon" @click="handleRemove(item, index)">
                    <i class="el-icon-error"></i>
                  </div>
                </div>
              </div>
              <div class="upload-box">
                <el-upload
                  :show-file-list="false"
                  list-type="picture-card"
                  class="upload-demo"
                  drag
                  :action="`${commonUrl.baseurl}` + '/oss/fileUploadByFromData'"
                  multiple
                  :before-upload="beforeUpload"
                  :on-change="fileChange"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text" style="padding: 0 20px">
                    Max file size: 10MB. Types supported: jpg, jpeg, png, pdf,
                    docx, doc, xlsx, xls.
                  </div>
                </el-upload>
              </div>
              <div class="checked-box">
                <el-checkbox v-model="agreeChecked"
                  >I agree to share my Business Card with quoted
                  suppliers.</el-checkbox
                >
              </div>
              <div class="checked-box">
                <el-checkbox v-model="agreeRules"
                  >I have read, understood and agreed to abide by the
                  <el-link
                    @click.prevent="clickRules"
                    :underline="false"
                    type="primary"
                    >Buying Request Posting Rules
                  </el-link>
                </el-checkbox>
              </div>
              <div class="submit-btn">
                <el-button
                  :loading="submitLoading"
                  type="warning"
                  size="small"
                  class="add-btn"
                  round
                  @click="submit"
                >
                  Invite suppliers to quote</el-button
                >
              </div>
            </el-card>
          </el-card>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
    <RfqsRules :visible.sync="visible"></RfqsRules>
  </div>
</template>

<script>
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import { createRFQ, findQuantityUnit } from "@/api/service/RFQsApi";
import RfqsRules from "./RfqsRules";
import commonUrl from "@/common/commonurl";
export default {
  components: {
    shopHead,
    commonFooter,
    RfqsRules,
  },
  data() {
    return {
      form: {},
      rules: {
        productName: [
          {
            required: true,
            message: "Please enter the product name！",
            trigger: "blur",
          },
        ],
        quantity: [
          {
            required: true,
            message: "Please enter Sourcing quantity！",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "Please enter unit！",
            trigger: "blur",
          },
        ],
        require: [
          {
            required: true,
            message: "Please enter Detailed requirements！",
            trigger: "blur",
          },
          {
            min: 20,
            message: "Enter at least 20 characters",
            trigger: "blur",
          },
        ],
      },
      unitOptions: [],
      agreeChecked: true,
      agreeRules: true,
      visible: false,
      submitLoading: false,
      commonUrl: "",
      fileList: [],
      imgSrcList: [],
    };
  },
  created() {
    this.commonUrl = commonUrl;
    this.findQuantityUnit();
  },
  methods: {
    findQuantityUnit() {
      findQuantityUnit({}).then((res) => {
        const data = res.data;
        if (data.bizCode.code === "0") {
          this.unitOptions =
            data.resultSet && data.resultSet.length > 0 ? data.resultSet : [];
        } else {
          this.$message.error(data.bizCode.info);
        }
      });
    },
    submit() {
      let images = "";
      if (this.fileList.length > 0) {
        this.fileList.forEach((item) => {
          images += item.imgUrl + ",";
        });
      }
      let param = {
        ...this.form,
        images: images,
      };
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.require.trim() === "") {
            return this.$message.error("Please enter Detailed requirements！");
          }
          if (!this.agreeChecked) {
            return this.$message.error(
              "please Agree to share my business card with the bidder supplier ！"
            );
          }
          if (!this.agreeRules) {
            return this.$message.error(
              "Please agree to comply with the Buying Request Posting Rules ！"
            );
          }
          this.submitLoading = true;
          createRFQ(param).then((res) => {
            if (res.data.bizCode.code === "0") {
              this.$message.success(res.data.bizCode.info);
              this.$router.push({
                path: "/RFQs",
              });
            } else {
              this.$message.error(res.data.bizCode.info);
            }
            this.submitLoading = false;
          });
        }
      });
    },
    clickRules() {
      this.visible = true;
    },
    beforeUpload(file) {
      const isXls =
        file.name.search(/(.png|.jpeg|.jpg|.pdf|.docx|.doc|.xlsx|.xls)/gi) > -1;
      if (!isXls) {
        this.$message.warning(
          "Upload file format error, only supported（.png，.jpeg，.jpg，.pdf，.docx，.doc，.xlsx，.xls）"
        );
      }
      const isLt = file.size / 1024 / 1024 < 10;
      if (!isLt) {
        this.$message.error(
          "The size of the uploaded file cannot exceed 10MB!"
        );
      }
      return isXls && isLt;
    },
    fileChange(file, fileList) {
      let res = file.response;
      if (file.response && res.result) {
        let type = this.isPdfOrImage(res.result);
        file.imgFileType = type;
        file.imgUrl = res.result;
        if (type === "image") {
          this.imgSrcList.push(file.imgUrl);
        }
        this.fileList.push({
          imgFileType: file.imgFileType,
          imgUrl: file.imgUrl,
          url: file.imgUrl,
          name: file.name,
        });
      }
    },
    isPdfOrImage(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      if (["pdf", "docx", "doc", "xlsx", "xls"].includes(extension)) {
        return "file";
      } else if (["jpg", "jpeg", "png"].includes(extension)) {
        return "image";
      } else {
        return "";
      }
    },
    handleRemove(item, index) {
      let imgSrcList = [];
      this.fileList.splice(index, 1);
      this.fileList.forEach((item) => {
        if (item.imgFileType === "image") {
          imgSrcList.push(item.imgUrl);
        }
      });
      this.imgSrcList = imgSrcList;
      this.$message.success("已删除");
    },
    clickFile(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.content_wrapper {
  width: 100%;
  margin: 0 auto;

  .container {
    width: 1200px;
    margin: 0 auto;
  }

  .add-cont {
    margin: 25px 0;
  }

  .breadcrumb-item {
    margin-top: 25px;

    ::v-deep .el-breadcrumb__inner.is-link {
      font-weight: 500;
    }
  }

  .add-cont {
    .title {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    ::v-deep .el-textarea {
      textarea {
        resize: none;
      }
    }
  }

  .checked-box {
    ::v-deep .el-checkbox__label {
      font-size: 14px;
      color: #333;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #f60;
      border-color: #f60;
    }
  }

  .submit-btn {
    text-align: center;
    margin: 20px 0;
  }

  .add-btn {
    font-size: 14px;
    background-color: #f60;
    border-color: #f60;
  }

  .upload-box {
    width: 200px;
    margin-bottom: 15px;

    ::v-deep .el-upload-dragger {
      width: 100%;
    }

    ::v-deep .el-icon-upload {
      font-size: 50px;
      margin: 10px 0 10px;
    }

    ::v-deep .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 25px;
    }
  }

  .file-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .upload-show-img {
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    border: 1px dashed #d9d9d9;
    font-size: 24px;
  }

  .file-item {
    position: relative;
  }

  .remove-icon {
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 99;
    cursor: pointer;
    font-size: 20px;
    color: #c0c4cc;

    &:hover {
      color: #f60;
    }
  }
}
</style>
