<template>
  <el-dialog
    :title="'Terms and Conditions Governing Request for Quotation'"
    :visible.sync="visible"
    width="900px"
    :before-close="closeDialog"
    center
  >
    <div>
      By using the Request for Quotation service, you agree that you have read,
      understood, and agreed to be bound by the Terms of Use, Privacy Policy,
      and Product Listing Policy of Gobbmart.com (“BBmart Rules”) , agreementas
      well as the following terms with regards to your Buying Request:
      <br />1. Your Buying Request should be specific and clear.You should not
      post any request or information irrelevant to the Request for Quotation
      service. <br />2.Your Buying Request should be specific and clear.You
      should not post any request or information irrelevant to the RFQ service.
      <br />3.To avoid causing any confusion, you should not post repeated
      Buying Requests. <br />4.System generated emails will be sent to you to
      facilitate notifications of Buying Request status and quotation updates
      pursuant to your request under the Request for Quotation service. 
      <br />5. You undertake that you shall not publish any information or
      conduct any act whatsoever that infringe the rights of any third party,
      including but not limited to any intellectual property rights, rights of
      publicity, rights of personality, rights of privacy, and any other rights
      of third parties not specifically identified in this clause. <br />6.In
      the event that you have violated any of the terms stated
      above,Gobbmart.com shall have the right to take any enforcement actions
      reasonably necessary. Examples of such enforcement actions include but not
      limited to deleting your posted Buying Request, restricting your rights to
      post the Buying Request, issuing warnings to you, or terminating your
      membership agreement withGobbmart.com, and etc. <br />7.You acknowledge
      and agree that Gobbmart.com may use your membership information, including
      but not limited to your company profile, profile pictures, transaction
      history, contact person information, and etc., to market and promote
      Request for Quotation and the BBmart Website (URL:
      http://www.Gobbmart.com) and also to support and operate the various
      programs and services of Request for Quotation offered to members from
      time to time. <br />8.Gobbmart.com reserves the right to match any Request
      for Quotation (“RFQ”) only with quotations that are deemed to be suitable
      for the RFQ. Gobbmart.com also reserves the right to refuse to match any
      RFQ with quotations that do not meet any criteria as deemed appropriate
      and required byGobbmart.com in its sole discretion. Examples of such
      quotations include, but are not limited to, quotations for products that
      do not belong to the supplier’s designated category of products on
      Gobbmart.com, quotations that do not correspond to the products,
      quotations with missing information, quotations for banned and/or
      restricted products, quotations that infringe any terms and conditions for
      the use of Request for Quotation or any other website rules adopted by
      Gobbmart.com, and etc. <br />9.You shall not submit any information or
      materials, in particular during your use of the audio and video submission
      services of the Gobbmart.com mobile app, that is/are unlawful in any
      relevant country or jurisdiction and/or are in breach of any website rules
      adopted by Gobbmart.com.  In addition, you shall not submit any material
      that may not be restricted or prohibited by law but are nonetheless
      controversial, including but not limited to: <br />1).Information that
      encourage illegal activities; <br />2).Items that are racially,
      religiously or ethnically derogatory, or that promote hatred, violence,
      racial or religious intolerance; <br />3).Giveaways, lotteries, raffles,
      or contests; <br />4).Stocks, bonds, investment interest and other
      securities;and <br />5).Pornographic materials/items that are sexual in
      nature. <br />10.You acknowledge and agree that Gobbmart.com may use
      information submitted by you pursuant to any Buying Request including, but
      not limited to, any related product information, product pictures, product
      specifications therein, and etc., to support and operate various programs,
      services, marketing initiatives, and other services offered by the RFQ
      service to members from time to time. <br />11.You acknowledge and agree
      that Gobbmart.com may provide your contact information, including but not
      limited your contact name,email address, telephone, mobile phone and
      fax,to suppliers for them to contact you for further business
      opportunities. <br />12.Gobbmart.com reserves the right to amend, vary
      and/or modify these rules upon notice. In the event of disputes, the
      decision of Gobbmart.com shall be final and binding.
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">Read already</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style></style>
